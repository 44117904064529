<template>
  <chart
    :chartData="chartData"
    :options="chartOptionsMerged"
    :addDataLablesPlugin="addDataLablesPlugin"
  ></chart>
</template>

<script>
  import chart from '@/components/charts/lib/HorizontalBar.vue';
  import merge from 'lodash/merge';

  export default {
    name: 'HorizontalBarChart',
    props: {
      loading: {
        required: false
      },
      chartData: {
        required: false
      },
      chartOptions: {
        required: false,
        default: () => ({})
      },
      title: {
        required: false
      },
      xAxisLabel: {
        required: false
      },
      yAxisLabel: {
        required: false
      },
      addDataLablesPlugin: {
        required: false,
        default: false
      }
    },
    components: { chart },
    data: function () {
      return {
        defaultChartOptions: {
          title: {
            display: !!this.title,
            text: this.title
          },
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            enabled: false,
            custom: function (tooltipModel) {
              // Tooltip Element
              var tooltipEl = document.getElementById('chartjs-tooltip');

              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<table></table>';
                document.body.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
              }

              // Set caret Position
              tooltipEl.classList.remove('above', 'below', 'no-transform');
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add('no-transform');
              }

              function getBody(bodyItem) {
                return bodyItem.lines;
              }

              // Set Text
              if (tooltipModel.body) {
                var titleLines = tooltipModel.title || [];
                var bodyLines = tooltipModel.body.map(getBody);

                var innerHtml = '<thead>';

                titleLines.forEach(function (title) {
                  if (title != 'undefined') {
                    innerHtml += '<tr><th>' + title + '</th></tr>';
                  }
                });
                innerHtml += '</thead><tbody>';

                bodyLines.forEach(function (body, i) {
                  var colors = tooltipModel.labelColors[i];
                  var style = 'background:' + colors.backgroundColor;
                  style += '; border-color:' + colors.borderColor;
                  style += '; border-width: 2px';
                  var span =
                    '<span class="chartjs-tooltip-key" style="' +
                    style +
                    '"></span>';
                  innerHtml += '<tr><td>' + span + body + '</td></tr>';
                });
                innerHtml += '</tbody>';

                var tableRoot = tooltipEl.querySelector('table');
                tableRoot.innerHTML = innerHtml;
              }

              // `this` will be the overall tooltip
              var position = this._chart.canvas.getBoundingClientRect();

              // Display, position, and set styles for font
              tooltipEl.style.opacity = 1;
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.left =
                position.left + window.pageXOffset + tooltipModel.caretX + 'px';
              tooltipEl.style.top =
                position.top + window.pageYOffset + tooltipModel.caretY + 'px';
              tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
              tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
              tooltipEl.style.padding =
                tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
              tooltipEl.style.pointerEvents = 'none';
            }
          },
          legend: {
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: !!this.xAxisLabel,
                  labelString: this.xAxisLabel
                },
                ticks: {
                  beginAtZero: true
                }
              }
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: !!this.yAxisLabel,
                  labelString: this.yAxisLabel
                }
              }
            ]
          }
        }
      };
    },
    computed: {
      chartOptionsMerged() {
        // needed deep merging
        return merge(this.defaultChartOptions, this.chartOptions);
      }
    }
  };
</script>

<!--
EXAMPLES

DATA
{
  datasets: [{
    label: 'Dataset 1',
    backgroundColor: "red",
    data: [1, 2, 3]
  }, {
    label: 'Dataset 2',
    backgroundColor: "blue",
    data: [2, 3, 4]
  }],

  labels: ['Point A', 'Point B', 'Point C']
}

OPTIONS
{
  title: {
    display: this.title ? true : false,
    text: this.title
  },
    responsive: true,
    maintainAspectRatio: false,
    // aspectRatio: 3,
    tooltips: {
    enabled: true
  },
  legend: {
    // onClick: function() { return; }
  },
    scales: {
      xAxes: [{
        scaleLabel: {
          display: this.xAxisLabel ? true : false,
          labelString: this.xAxisLabel
        },
        ticks: {
          beginAtZero: true
        }
      }],
      yAxes: [{
        scaleLabel: {
          display: this.yAxisLabel ? true : false,
          labelString: this.yAxisLabel
        }
      }]
    }
  }
}
-->
